<template>
  <div class="min-h-screen min-w-min h-auto inset-0 bg-gradient-to-r from-emerald-200 to-blue-300 pt-14">
    <MainMenu />

    <router-view />

    <FooterComponent />

    <CookiesBanner />
  </div>
</template>

<script>
import MainMenu from '@/components/MainMenu'
import FooterComponent from '@/components/FooterComponent'
import CookiesBanner from '@/components/CookiesBanner'

export default {
  components: {
    MainMenu, FooterComponent, CookiesBanner
  }
}
</script>
