import { createRouter, createWebHistory } from 'vue-router'
import { setPageTitle } from '@/helpers/page-meta'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'main', toTop: true, title: 'GoodPlaceToTrackYourMoney' },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/legal',
    name: 'Legal',
    meta: { layout: 'main', toTop: true, title: 'LegalInformation' },
    component: () => import('../views/Legal.vue')
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    const scroll = {}
    if (to.meta.toTop) {
      scroll.top = 0
      scroll.behavior = 'smooth'
    }

    return scroll
  }
})

router.beforeEach((to, from, next) => {
  setPageTitle(to.meta.title)
  next()
})

export default router
