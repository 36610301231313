<template>
  <div id="app">
    <component :is="layout" />
  </div>
</template>

<script>
import MainLayout from '@/layouts/MainLayout'
import { setPageMetaTags } from '@/helpers/page-meta'

export default {
  components: {
    MainLayout
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || 'main') + '-layout'
    }
  },
  created() {
    if (navigator.language) {
      this.$store.dispatch('newAppLanguage', { locale: navigator.language })
    }

    setPageMetaTags()
  }
}
</script>
