import { createStore } from 'vuex'
import { getAnalytics, logEvent } from 'firebase/analytics'
import Locales from '@/libraries/locales'
import ShortLocales from '@/libraries/short.locales'
import router from '../router'
import { setPageTitle, setPageMetaTags, setPageLang } from '@/helpers/page-meta'

export default createStore({
  state: {
    locale: 'en-US',
    countryRemoteConfig: null
  },
  mutations: {
    setAppLanguage(state, locale) {
      state.locale = locale
    },
    setCountryRemoteConfig(state, country) {
      state.countryRemoteConfig = country
    }
  },
  actions: {
    logEvent(context, { eventName = null, eventParams = null }) {
      if (!eventName) { return }

      try {
        if (eventParams) {
          logEvent(getAnalytics(), eventName, eventParams)
        } else {
          logEvent(getAnalytics(), eventName)
        }
      } catch (e) {
        //
      }
    },
    newAppLanguage({ commit, dispatch }, { locale, save }) {
      if (!locale) { return }

      const arr = locale.split('-')
      if (arr && arr.length) {
        if (arr.length === 2) {
          arr[0].toLowerCase()
          arr[1].toUpperCase()
          locale = arr.join('-')
        } else if (arr.length === 1) {
          arr[0].toLowerCase()
          if (ShortLocales[arr[0]]) {
            locale = ShortLocales[arr[0]]
          }
        }
      }

      if (!locale || !Locales[locale]) { locale = 'en-US' }

      commit('setAppLanguage', locale)

      setPageTitle(router.currentRoute.value.meta.title)
      setPageMetaTags()
      setPageLang(locale)

      if (save) { dispatch('logEvent', { eventName: save, eventParams: locale }) }
    }
  },
  getters: {
    locale: s => s.locale,
    countryRemoteConfig: s => s.countryRemoteConfig
  }
})
