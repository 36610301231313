<template>
  <div>
    <div class="enter-from-top fixed z-20 w-full top-0 sm:top-2">
      <div class="sm:w-auto sticky bg-sky-500 shadow-lg sm:rounded-md mx-0 sm:mx-5 px-5 py-2 text-gray-800">
        <div class="flex justify-between items-start sm:items-center">
          <div
            v-if="isMainPage"
            class="flex items-center flex-col"
          >
            <div
              class="hover:opacity-75 cursor-pointer flex sm:hidden items-center"
              @click.prevent.stop="showMenu = !showMenu"
            >
              <img
                v-if="!showMenu"
                src="../../public/img/app-images/menu_18dp.svg"
                class="h-6 w-6"
              >

              <img
                v-else
                src="../../public/img/app-images/chevron_right_18dp.svg"
                class="h-6 w-6 rotate-90"
              >
            </div>

            <div
              class="sm:flex flex-col sm:flex-row items-start text-gray-200"
              :class="{'hidden': !showMenu}"
            >
              <div
                class="mr-2 hover:opacity-75 cursor-pointer"
                @click.prevent.stop="goTo('Features')"
              >
                {{ $filters.localizeFilter('Features') }}
              </div>

              <div
                class="mr-2 hover:opacity-75 cursor-pointer"
                @click.prevent.stop="goTo('Pricing')"
              >
                {{ $filters.localizeFilter('Pricing') }}
              </div>
            </div>
          </div>

          <div
            v-else
            class="flex items-center flex-col"
          >
            <div
              class="hover:opacity-75 cursor-pointer flex items-center"
              @click.prevent.stop="goHome()"
            >
              <img
                src="../../public/img/app-images/home_24dp.svg"
                class="h-6 w-6"
              >
            </div>
          </div>

          <div class="flex items-center">
            <div
              class="hover:opacity-75 cursor-pointer flex items-center"
              @click.prevent.stop="showLanguages = true"
            >
              <img
                src="../../public/img/app-images/language_18dp.svg"
                class="h-5 w-5"
              >

              <span
                v-if="locale && locales[locale] && locales[locale].nameNative"
                class="text-gray-600 ml-1 text-sm"
              >
                {{ locales[locale].nameNative }}
              </span>
            </div>

            <div
              class="hover:opacity-75 cursor-pointer ml-2 sm:ml-4 px-2 bg-emerald-400 bg-opacity-70 border-opacity-40 rounded-md hover:shadow-sm"
              @click.prevent.stop="openApp()"
            >
              {{ $filters.localizeFilter('Login') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="fade3">
      <Languages
        v-if="showLanguages"
        @close-pannel="showLanguages = false"
        @chosen-language="setLanguage($event), showLanguages = false"
      />
    </transition>
  </div>
</template>

<script>
import Languages from '@/components/Languages'
import Locales from '@/libraries/locales'

export default {
  components: { Languages },
  computed: {
    locale() {
      return this.$store.getters.locale
    },
    isMainPage() {
      return this.$route.name === 'Home'
    }
  },
  data: () => ({
    locales: Locales,
    showMenu: false,
    showLanguages: false
  }),
  methods: {
    setLanguage (locale) {
      this.$store.dispatch('newAppLanguage', { locale, save: 'setAppLanguageClickedCom' })
    },
    openApp() {
      window.open(`https://my.bluemoneybox.com?lang=${this.locale}`, '_blank')
      this.$store.dispatch('logEvent', { eventName: 'openAppClickedFromMenuCOM', eventParams: { locale: this.locale } })
    },
    goTo(location) {
      this.showMenu = false

      if (!location) { return }

      const block = document.getElementById(location)
      if (!block || !block.offsetTop) { return }

      setTimeout(async () => {
        window.scrollTo({
          left: 0,
          top: block.offsetTop - 75,
          behavior: 'smooth'
        })
      })

      this.$store.dispatch('logEvent', { eventName: 'goTo' + location + 'ClickedCOM', eventParams: { location: location } })
    },
    goHome() {
      this.$router.push({ name: 'Home' })
      this.$store.dispatch('logEvent', { eventName: 'goHomeClickedCOM' })
    }
  }
}
</script>

<style scoped>
.enter-from-top {
  animation: fromTop 0.5s;
}

.enter-from-left {
  animation: fromLeft 0.3s;
}

@keyframes fromTop {
  from {top:-100%;}
  to {top:0;}
}

@keyframes fromLeft {
  from {left:-100%;}
  to {left:0;}
}
</style>
