<template>
  <div class="fixed z-30 min-h-full inset-0 overflow-y-auto">
    <div
      class="min-w-screen flex justify-center items-start"
      @contextmenu.prevent.stop=""
    >
      <div class="enter-from-right relative z-20 w-11/12 md:w-3/4 lg:w-1/2 bg-white rounded-md shadow-xl sm:max-w-lg mx-4 my-10 md:my-20">
        <div class="flex justify-start text-left text-sm pl-4 pb-4 pr-6 pt-4">
          <span class="flex flex-col w-full">
            <CloseButton
              @clicked="$emit('close-pannel')"
            />

            <h3 class="sm:flex sm:items-start mb-3 sm:ml-4 text-center sm:text-left text-lg leading-6 font-medium text-gray-900">
              {{ $filters.localizeFilter('LanguageSelection') }}
            </h3>

            <div class="flex flex-wrap px-4">
              <div
                v-for="locale in localesArr"
                :key="locale"
                class="mr-8 text-blue-500 border-b border-blue-500 border-dotted cursor-pointer hover:opacity-75"
                @click.prevent.stop="$emit('chosen-language', locale)"
              >
                <span v-if="locales[locale] && locales[locale].nameNative">
                  {{ locales[locale].nameNative }}
                </span>
              </div>
            </div>
          </span>
        </div>
      </div>

      <div
        class="fixed inset-0 bg-gray-500 opacity-75"
        @click="$emit('close-pannel')"
      />
    </div>
  </div>
</template>

<script>
import CloseButton from '@/components/CloseButton'
import Locales from '@/libraries/locales'

export default {
  name: 'LanguagesComponent',
  components: {
    CloseButton
  },
  data: () => ({
    locales: Locales
  }),
  computed: {
    localesArr () {
      let arr = Object.keys(Locales)

      if (arr.length > 1) {
        arr.sort((a, b) => {
          const nameA = Locales[a].nativeName || ''
          const nameB = Locales[b].nativeName || ''

          if (nameA < nameB) { return -1 }
          if (nameA > nameB) { return 1 }
          return 0
        })
      }

      return arr
    }
  }
}
</script>

<style scoped>
.enter-from-right {
  animation: fromRight 0.5s;
}

@keyframes fromRight {
  from {right:-100%;}
  to {right:0;}
}
</style>
