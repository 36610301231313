import store from '../store'
import ruRU from '../locales/ru-RU.json'
import enUS from '../locales/en-US.json'

const locales = {
  'ru-RU': ruRU,
  'en-US': enUS
}

export default function localizeFilter(key) {
  const locale = store.getters.locale || 'en-US'

  let localeKey = locales[locale][key]

  if (!localeKey) {
    localeKey = locales['en-US'][key]

    if (!localeKey) {
      localeKey = 'NO-KEY-FOUND'
    }
  }

  return localeKey
}
