<template>
  <div
    v-if="show"
    class="cookies-banner fixed left-0 bottom-0 mx-2 my-2 rounded-md shadow-xl w-auto max-w-md text-sm font-medium text-gray-700 px-4 py-2 bg-amber-100"
  >
    {{ $filters.localizeFilter('CookiesBannerText') }}

    <div class="flex justify-center sm:justify-start">
      <button
        class="mt-1 bg-gray-50 px-10 sm:px-2 py-0.5 focus:outline-none hover:opacity-75 cursor-pointer select-none rounded-md"
        @click.prevent.stop="okClicked()"
      >
        OK
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    show: true,
    localStorageName: 'blue-money-box-com-cookies'
  }),
  async created () {
    const localStorageDataStr = localStorage.getItem(this.localStorageName)
    if (localStorageDataStr) {
      const ls = JSON.parse(localStorageDataStr)

      if (ls && ls.timestamp) {
        if ((Date.now() - +ls.timestamp) > 2592000000) {
          localStorage.removeItem(this.localStorageName)
          this.show = true
        } else {
          this.show = false
        }
      }
    }
  },
  methods: {
    okClicked () {
      this.show = false

      localStorage.setItem(this.localStorageName, JSON.stringify({
        timestamp: Date.now()
      }))

      this.$store.dispatch('logEvent', { eventName: 'cookiesBannerOkClickedCOM' })
    }
  }
}
</script>


<style scoped>
.cookies-banner {
  z-index: 1000;
}
</style>
