<template>
  <button
    class="absolute top-1.5 right-1.5 bg-transparent focus:outline-none hover:opacity-75 cursor-pointer select-none"
    @click.prevent.stop="$emit('clicked')"
  >
    <img
      src="../../public/img/app-images/close_18dp.svg"
      class="w-6 sm:w-5 h-6 sm:h-5"
    >
  </button>
</template>
